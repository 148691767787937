/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedPatientUserVisitListSchema } from '../models/PagedPatientUserVisitListSchema';
import type { PatientUserDetailSchema } from '../models/PatientUserDetailSchema';
import type { VisitFilterChoices } from '../models/VisitFilterChoices';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PatientPortalApi {
    /**
     * Get Patient User Visit List
     * Get all visits for authenticated PatientUser.
     * @returns PagedPatientUserVisitListSchema OK
     * @throws ApiError
     */
    public static patientPortalGetPatientUserVisitListGet({
        query,
        ordering,
        limit = 100,
        offset,
    }: {
        query?: (VisitFilterChoices | null),
        ordering?: (string | null),
        limit?: number,
        offset?: number,
    }): CancelablePromise<PagedPatientUserVisitListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/patient-portal/',
            query: {
                'query': query,
                'ordering': ordering,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Get Patient User Detail
     * Get authenticated PatientUser detail.
     * @returns PatientUserDetailSchema OK
     * @throws ApiError
     */
    public static patientPortalGetPatientUserDetailGet(): CancelablePromise<PatientUserDetailSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/patient-portal/me',
        });
    }
}
